import { get, post } from '@/utils/request';

// 请求接口
export const usersSendCode = (data) => post('users/send_code',data); // 发送登录或注册验证码
export const usersLogin = (data) => post('users/login',data); // 登录或注册
export const usersInfo = (param) => get('users/user_data',param); // 用户信息
export const usersUpdate = (data) => post('users/update',data); // 修改用户信息
export const orderCreate = (data) => post('order/create',data); // 在线下单
export const orderIndex = (param) => get('order/index',param); // 订单列表
export const orderTrack = (param) => get('order/order_track',param); // 货物追踪
export const orderTrack1 = (param) => get('order/order_track',param); // 货物追踪
export const bankSmsCode = (data) => post('users/bank_sms_code',data); // 添加银行卡发送短信
export const bankCardAdd = (data) => post('users/bank_card_add',data); // 添加银行卡
export const bankIndex = (param) => get('users/bank_index',param); // 银行卡列表
export const unionpaySms = (param) => get('order/unionpay_sms',param); // 银联支付（1）发送验证码
export const unionpay = (param) => get('order/unionpay',param); // 银联支付（2）提交验证码进行支付
export const eBankList = (param) => get('order/e_bank_list',param); // 网银支付银行列表
export const eBankPay = (param) => get('order/e_bank_pay',param); // 网银支付
export const bankDel = (param) => get('users/bank_del',param); // 删除银行卡
export const addressAdd = (data) => post('users/address_add',data); // 添加收发件地址
export const address = (param) => get('users/address',param); // 收发件地址列表
export const defaultAddress = (param) => get('users/default_address',param); // 默认的收发件地址
export const defaultUpdate = (param) => get('users/default_update',param); // 修改收发件默认地址
export const addressUpdate = (data) => post('users/address_update',data); // 修改收发件地址
export const addressDel = (param) => get('users/address_del',param); // 删除收发件地址
export const publicPay = (param) => get('order/upg_pay',param); // 银联商户-天满服务平台-企业网银支付
export const zhongxinPay = (param) => get('order/zhongxin',param); // 中信全付通
export const ecepcpay = (param) => get('order/ecepcpay',param); // 中信全付通